$(".copy-link").on("click", function(e) {
    e.preventDefault();

    var $this = $(this);
    var label = $this.children(".copy-link__label");
    var url = $this.attr("value");

    navigator.clipboard.writeText(url);

    label.text("Link copied");
    setTimeout(function () {
      label.text("Copy link");
    }, 3000);
});